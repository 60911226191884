import * as React from "react";
import * as formcarry from "@formcarry/react";
import { navigate } from "gatsby";

import Layout from "../components/Layout/Layout";
import Head from "../components/Head/Head";
import Button from "../components/Button/Button";
import socialBanner from "../media/banner.png";

import * as styles from "./contact.module.scss";

function Contact(): JSX.Element {
  const { state, submit: handleSubmit } = formcarry.useForm({
    id: "7r9zNAEb8tN",
  });
  const successClassName = new Set([styles.success]);
  if (state.submitted) {
    successClassName.add(styles.visible);
  }
  return (
    <Layout hideBackToTop hideContact>
      <Head
        meta={[
          {
            name: "og:image",
            content: socialBanner,
          },
          {
            name: "og:image:alt",
            content: "Logo of Justine Severino",
          },
          {
            name: "twitter:image",
            content: socialBanner,
          },
          {
            name: "twitter:image:alt",
            content: "Logo of Justine Severino",
          },
        ]}
        title="Contact"
      />
      <div className={styles.container}>
        <div data-breeze="slide-up">
          <Button
            className={styles.backButton}
            onClick={(): void => {
              // eslint-disable-next-line no-void
              void navigate(-1);
            }}
            type="button"
            variant="link"
          >
            <span className={styles.backButtonArrow}>&larr;</span>&nbsp;
            <span className={styles.backButtonText}>back</span>
          </Button>
        </div>
        <div className={styles.contact}>
          <div className={styles.intro}>
            <h1 data-breeze="slide-up" data-breeze-delay="1">
              Let’s work together
            </h1>
            <p
              className={styles.introText}
              data-breeze="slide-up"
              data-breeze-delay="2"
            >
              If you’ve got a design opportunity, feel free to drop me a
              message. If our ingredients match, let’s get the juices flowing!
            </p>
          </div>
          <p className={Array.from(successClassName).join(" ")}>
            Your message was sent successfully.
          </p>
          <form onSubmit={handleSubmit}>
            <div
              className={styles.formRow}
              data-breeze="slide-up"
              data-breeze-delay="3"
            >
              <input
                required
                className={styles.formControl}
                name="name"
                placeholder="Name and/or Company Name"
                type="text"
              />
            </div>
            <div
              className={styles.formRow}
              data-breeze="slide-up"
              data-breeze-delay="4"
            >
              <input
                required
                className={styles.formControl}
                name="email"
                placeholder="Email"
                type="email"
              />
            </div>
            <div
              className={styles.messageRow}
              data-breeze="slide-up"
              data-breeze-delay="5"
            >
              <textarea
                required
                className={styles.formControl}
                name="message"
                placeholder="Message"
                rows={5}
              />
            </div>
            <div
              className={styles.submitRow}
              data-breeze="slide-up"
              data-breeze-delay="2"
            >
              <input name="_gotcha" type="hidden" />
              <Button
                className={styles.submitButton}
                disabled={state.submitting}
                type="submit"
                variant="primary"
              >
                send
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
